import React from 'react';
import { Button, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { CreateGamePage } from './pages/CreateGamePage/CreateGamePage';
import { JoinGamePage } from './pages/JoinGamePage/JoinGamePage';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <CreateGamePage />,
  },
  {
    path: "/game/create",
    element: <CreateGamePage />,
  },
  {
    path: "/game/join/:gameId",
    element: <JoinGamePage />,
  }
]);

function App() {
  return (
    <div className='App'>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </div>
  );
}

export default App;
