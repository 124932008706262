import React, { useRef, useState } from 'react';
import './CreateGamePage.scss';
import { Button, TextField } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { useGameClient } from '../../clients/GameClient';
import { useNavigate } from 'react-router-dom';

export const CreateGamePage = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [createDisabled, setCreateDisabled] = useState(true);

    const nameRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const confirmPasswordRef = useRef<HTMLInputElement>(null);

    const gameClient = useGameClient();
    const navigate = useNavigate();

    const onCreateClick = () => {
        if (nameRef.current == null || passwordRef.current == null || confirmPasswordRef.current == null)
            return;

        setIsLoading(true);
        gameClient.createGame({
            name: nameRef.current.value,
            password: passwordRef.current.value
        }).then((gameId) => {
            setIsLoading(false);
            navigate(`/game/join/${gameId}`, {
                state: {
                    password: passwordRef.current?.value
                }
            });
        }).catch(errorMessage => {
            setIsLoading(false);
            setErrorMessage(errorMessage);
        });
    };

    const updateCreateDisabled = () => {
        if (nameRef.current == null || passwordRef.current == null || confirmPasswordRef.current == null)
            return;

        const isNameValid = nameRef.current.value.length > 0;
        const isPasswordValid = passwordRef.current.value.length > 7;
        const isPasswordConfirmed = passwordRef.current.value === confirmPasswordRef.current.value;

        if (!isNameValid || !isPasswordValid || !isPasswordConfirmed) {
            setCreateDisabled(true);
            return;
        }

        setCreateDisabled(false);
    };

    return (
        <div className='CreateGamePage'>
            <h2 className='CreateGamePage__Title'>Neues Spiel erstellen</h2>
            <div className='CreateGamePage__InputSection'>
                <TextField
                    placeholder='Name des Spiels'
                    fullWidth
                    size='small'
                    inputRef={nameRef}
                    onChange={updateCreateDisabled}
                />
                <TextField
                    className='CreateGamePage__PasswordField'
                    placeholder='Passwort'
                    fullWidth
                    size='small'
                    type='password'
                    inputRef={passwordRef}
                    onChange={updateCreateDisabled}
                />
                <TextField
                    className='CreateGamePage__PasswordConfirmationField'
                    placeholder='Passwort erneut eingeben'
                    fullWidth
                    size='small'
                    type='password'
                    inputRef={confirmPasswordRef}
                    onChange={updateCreateDisabled}
                />
                <span className='CreateGamePage__PasswordHint'>Das Passwort muss mindestens 8 Zeichen lang sein.</span>
                {
                    errorMessage.length > 0 ? (
                        <div className='CreateGamePage__ErrorContainer'>
                            <ErrorOutline className='CreateGamePage__ErrorIcon' />
                            <span className='CreateGamePage__ErrorMessage'>{errorMessage}</span>
                        </div>
                    ) : null
                }
                <div className='CreateGamePage__CreateContainer'>
                    <Button
                        variant='contained'
                        disabled={isLoading || createDisabled}
                        onClick={onCreateClick}
                    >
                        {
                            isLoading ?
                                "Bitte warten..." :
                                "Spiel erstellen"
                        }
                    </Button>
                </div>
            </div>
        </div>
    );
};