import React, { useRef, useState } from 'react';
import './JoinGamePage.scss';
import { Button, TextField } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { useLocation, useParams } from 'react-router-dom';
import { useGameClient } from '../../clients/GameClient';

export const JoinGamePage = () => {
    const [joinDisabled, setJoinDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const { gameId } = useParams();
    const nameRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const gameClient = useGameClient();
    const location = useLocation();

    const hasPrefilledPassword = () => {
        return location.state != null && location.state.password != null && location.state.password.length !== "";
    };

    const getPrefilledPassword = () => {
        return location.state.password;
    };

    const getPassword = () => {
        if (hasPrefilledPassword())
            return getPrefilledPassword();

        if (passwordRef.current == null)
            return "";

        return passwordRef.current.value;
    };

    const updateJoinDisabled = () => {
        if (nameRef.current == null)
            return;
        const password = getPassword();
        const isNameValid = nameRef.current.value.length > 0;
        const isPasswordValid = password.length > 7;
        if (!isNameValid || !isPasswordValid) {
            setJoinDisabled(true);
            return;
        }

        setJoinDisabled(false);
    };

    const onJoinClick = () => {
        if (nameRef.current == null)
            return;

        setIsLoading(true);
        const password = getPassword();
        gameClient.joinGame({
            playerName: nameRef.current.value,
            gameId: gameId as string,
            password
        }).then(playerId => {
            setIsLoading(false);
            console.log(`JOINED! ${playerId}`);
        }).catch(errorMessage => {
            setIsLoading(false);
            setErrorMessage(errorMessage);
        });
    };

    return (
        <div className='JoinGamePage'>
            <h2 className='JoinGamePage__Title'>Spiel beitreten</h2>
            <div className='JoinGamePage__InputSection'>
                <TextField
                    placeholder='Spielername'
                    size="small"
                    fullWidth
                    inputRef={nameRef}
                    onChange={updateJoinDisabled}
                />
                {
                    !hasPrefilledPassword() ? (
                        <TextField
                            className='JoinGamePage__PasswordField'
                            placeholder='Passwort'
                            size="small"
                            type='password'
                            fullWidth
                            inputRef={passwordRef}
                            onChange={updateJoinDisabled}
                        />
                    ) : null
                }
                {
                    errorMessage.length > 0 ? (
                        <div className='JoinGamePage__ErrorContainer'>
                            <ErrorOutline className='JoinGamePage__ErrorIcon' />
                            <span className='JoinGamePage__ErrorMessage'>{errorMessage}</span>
                        </div>
                    ) : null
                }
                <div className='JoinGamePage__JoinContainer'>
                    <Button
                        variant='contained'
                        disabled={joinDisabled || isLoading}
                        onClick={onJoinClick}
                    >
                        {
                            isLoading ?
                                "Bitte warten..." :
                                "Beitreten"
                        }
                    </Button>
                </div>
            </div>
        </div>
    );
};