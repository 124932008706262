import axios from "axios";
import { ICreateGameRequest, IJoinGameRequest } from "./models";

export interface IGameClient {
    createGame: (req: ICreateGameRequest) => Promise<string>,
    joinGame: (req: IJoinGameRequest) => Promise<string>
}

export const useGameClient = (): IGameClient => {
    const createGame = (req: ICreateGameRequest): Promise<string> => {
        return new Promise((resolve, reject) => {
            axios({
                url: `${process.env.REACT_APP_BACKEND_BASE_URL}/game/create`,
                method: "POST",
                data: req
            }).then(response => resolve(response.data))
            .catch(error => {
                if (error.response == null) {
                    reject("Es ist ein Fehler aufgetreten.");
                    return;
                }

                reject(error.response.data);
            });
        });
    };

    const joinGame = (req: IJoinGameRequest): Promise<string> => {
        return new Promise((resolve, reject) => {
            axios({
                url: `${process.env.REACT_APP_BACKEND_BASE_URL}/game/join/${req.gameId}`,
                method: "POST",
                data: {
                    playerName: req.playerName,
                    password: req.password
                }
            }).then(response => resolve(response.data))
            .catch(error => {
                if (error.response == null) {
                    reject("Es ist ein Fehler aufgetreten.");
                    return;
                }

                reject(error.response.data);
            });
        });
    };

    return {
        createGame,
        joinGame
    };
};